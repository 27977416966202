/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import MaterialTable from "material-table";
import tableIcons, {tableOptions} from "../../components/universal/table_attributes";
import Button from "@material-ui/core/Button";
import BatchDetails from "./batch_details";
import {BatchContext} from "../../lib/contexts/batch_context";
import {RefreshContext} from "../../lib/contexts/refresh_context";
import {EventsContext} from "../../lib/contexts/events_context";
import BatchStatus, {statusMap} from "./batch_status_info";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import UserClient from "../../lib/services/api/user";
import SLACountDown from "../Admin/sla_countdown";

const useStyles = makeStyles(theme => ({
    root: {
        '& >div >.MuiToolbar-root': {
            paddingLeft: '8px !important'
        },
    },
    title: {
        fontSize: '2em',
        padding: '5px 0',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.3em'
        }
    },
    date: {
        fontSize: '0.7em'
    },
    processing: {
        color: '#fff',
        backgroundColor: '#fb8c00'
    },
    processed: {
        color: '#fff',
        backgroundColor: '#43a047'
    },
}))

function BatchesList(props) {

    const classes = useStyles();
    const [action, setAction] = useState();
    const [currentSelection, setCurrentSelection] = useState({});
    const [iniLoad, setIniLoad] = useState(false);
    const [tenantList, setTenantList] = useState([]);
    const [status, setStatus] = useState("ALL");
    const [tenant, setTenant] = useState([]);
    const Batch = useContext(BatchContext);
    const {getBatchList, setPage, setPageSize, setSearch} = Batch;
    const {data, page, pageSize, total, searchText} = Batch.data;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const RefreshObserver = useContext(RefreshContext);
    const {refresh} = RefreshObserver;

    const Event = useContext(EventsContext);

    useEffect(() => {
        if (props.batchId) {
            setCurrentSelection({id: props.batchId})
            setAction("view");
        }
    }, [props.batchId])

    useEffect(() => {
        const params = {
            page: page + 1,
            page_size: pageSize || 50,
            user_id: props.userId,
            batch_name: searchText || "",
            tenant_id: tenant,
            status: status === "ALL" ? undefined: status
        }
        getBatchList(params);
    }, [page, pageSize, refresh, props.userId, searchText, tenant, status]);

    useEffect(() => {
        UserClient.getTenants().then((res) => {
            if (res?.results?.length > 0) {
                setTenantList(res?.results);
            }
        }).catch((e) => {
        })
    }, []);

    useEffect(() => {
        Event.recordPageView("/users/batch/list");
        setIniLoad(true);
        const timeout = setTimeout(() => {
            setIniLoad(false);
        }, 3600);
        return (() => {
            setIniLoad(false);
            clearTimeout(timeout);
        })
    }, []);


    const handleClose = () => {
        setAction(null);
        try {
            if (props?.role === "MANAGER") {
                props.history.replace(-1);
            } else {
                props.history.push('/');
            }
        } catch (e) {
            props.history.push('/');
        }

        setCurrentSelection({});
    }

    const MITableOptions = {
        ...tableOptions,
        showTitle: true,
        paging: true,
        filter: true,
        pageSize: pageSize,
        pageSizeOptions: [10, 20, 30, 50, 100],
        search: false,
        selectableRowsOnClick: false,
    }

    const columns = [
        {
            title: 'Id',
            field: 'id',
            width: '50px',
            headerStyle: {
                textAlign: 'left',
                padding: 'auto 0'
            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Batch Name',
            field: 'name',
            width: '200px',
            headerStyle: {
                textAlign: 'left',
                padding: '10px 0'
            },
            cellStyle: {
                textAlign: 'left',
                padding: '10px 0'
            },
            render: rowData =>
                <div>
                    <Typography
                        style={{maxWidth: '360px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                        component={"div"} variant={'subtitle2'} gutterBottom>{rowData.name}
                    </Typography>
                </div>

        },
        {
            title: 'SLA',
            width: '15%',
            field: 'created_at',
            render: rowData => {
                const hours = rowData?.tenant_name?.toUpperCase() === 'ANGELOAK' ? 4 : 24;
                return (<SLACountDown status={rowData?.status} createdAt={moment(rowData.manual_requested_at ?? rowData.created_at)}
                              sla={moment(rowData.manual_requested_at ?? rowData.created_at).add( hours,'hours')}/>)
            }
        },
        {
            title: 'Manual at',
            field: 'manual_uploaded_at',
            render: rowData => { return (<Typography variant={"body1"}>{rowData?.manual_uploaded_at ? moment(rowData?.manual_uploaded_at).format("DD-MMM-YY HH:mm") : "N/A"}</Typography>)}
        },
        {
            title: 'Org',
            field: 'tenant_name',
            width: '15%',
            headerStyle: {
                textAlign: 'left',
                padding: 'auto 0'
            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Statements',
            field: 'file_count',
            width: '25px',
            headerStyle: {
                textAlign: 'left',
                padding: 'auto 0'
            },
            cellStyle: {
                textAlign: 'left',
                padding: '0 0 0 5%'
            }
        },
        {
            title: 'Results',
            field: 'result',
            width: '200px',
            headerStyle: {
                textAlign: 'left',
            },
            cellStyle: {
                padding: 0,
                textAlign: 'left'
            },
            render: rowData => {
                return (
                    <div>
                        <BatchStatus data={rowData}/>
                    </div>
                )
            }
        },
        {
            title: 'Action',
            field: 'id',
            width: '220px',
            headerStyle: {
                textAlign: 'center',
                padding: 0
            },
            cellStyle: {
                padding: 0,
                textAlign: 'right'
            },
            render: rowData => {
                const processingCount = +rowData?.file_count - (+rowData?.valid_files + +rowData?.invalid_files + +rowData?.new_bank_files);
                let statusDetail = statusMap[rowData.status]
                const noResult = rowData.status === 'NOT_UPLOADED' || Boolean(user.tenant_name?.toUpperCase() === "LENDSURE" ? (rowData.status === 'FAILED' && rowData.invalid_files > 2) : rowData.invalid_files > 0) || rowData.status?.indexOf('PROCESSING') !== -1;
                return (
                    <>
                        <Button
                            style={{width: '150px'}}
                            variant={!noResult ? "contained" : "outlined"}
                            size={"small"}
                            onClick={(e) => {
                                e.stopPropagation();
                                setCurrentSelection(rowData);
                                setAction('view');
                                if (!noResult && ['USER', 'MANAGER'].includes(user.role)) {
                                    props.history.push(`/user/batches/${rowData.id}/analysis`)
                                } else {
                                    setCurrentSelection(rowData);
                                    setAction('view');
                                }
                            }}
                            color={"primary"} startIcon={!noResult ? statusDetail?.actionIcon : null}>
                            {!noResult ? statusDetail?.actionLabel : "View Detail"}
                        </Button>
                    </>
                )
            }
        }
    ];

    const availableStatus = ["ALL", "PROCESSED_MANUALLY", "PROCESSING_MANUALLY", "FAILED"];

    return (
        <div className={classes.root}>
            <Typography variant={"h6"}>
                Filters
            </Typography>
            <div>
                <TextField
                    color="primary"
                    margin="dense"
                    placeholder={"All Status"}
                    label={"Status Filter"}
                    select
                    style={{width: '300px', marginRight: '15px'}}
                    variant="outlined"
                    value={status}
                    onChange={(e) => {
                        sessionStorage.setItem('status_filter', e.target.value);
                        setStatus(e.target.value);
                    }}
                >
                    {availableStatus.map((s, i) => (
                        <MenuItem className={"capitalize"} key={s} value={s}>
                            {s?.replace("_", " ")}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    color="primary"
                    margin="dense"
                    label={"Tenant Filter"}
                    placeholder={"All Tenants"}
                    style={{width: '300px'}}
                    select
                    SelectProps={{
                        MenuProps: {
                            style: {
                                maxHeight: 450,
                            },
                            anchorOrigin: {
                                vertical: "bottom"
                            }
                        },
                    }}
                    variant="outlined"
                    value={tenant}
                    onChange={(e) => {
                        sessionStorage.setItem('tenant_filter', e.target.value);
                        setTenant(e.target.value);
                    }}
                >
                    {tenantList.map((t, i) => (
                        <MenuItem key={t.id} value={t.id} style={{textTransform: 'capitalize'}}>
                            {t.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <MaterialTable
                title={<Typography className={classes.title} variant={'subtitle2'}>
                    Batches
                </Typography>}
                style={{boxShadow: 'unset'}}
                columns={columns}
                data={data || []}
                options={MITableOptions}
                icons={tableIcons}
                page={page}
                isLoading={iniLoad}
                pageSize={pageSize}
                totalCount={total}
                onChangePage={page => setPage(page)}
                onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No Batches found, Add new batch to view details',
                    }
                }}
            />
            <BatchDetails history={props.history} open={action === 'view'} handleClose={handleClose}
                          currentSelection={currentSelection}/>
        </div>
    );
}

export default BatchesList;
